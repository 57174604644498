interface AppointmentsAccessCode {
    valid_from: string,
    valid_to: string,
    code: string,
}


export const appointmentsAccessCodes = {
    state: {
        appointmentsAccessCodes: [] as AppointmentsAccessCode[],
    },
    
    mutations: {
        setAppointmentsAccessCodes(state: any, appointmentsAccessCodes: AppointmentsAccessCode[]) {
            state.appointmentsAccessCodes = appointmentsAccessCodes
        }
    },
    getters: {
        getAppointmentsAccessCodes(state: any): AppointmentsAccessCode[] {
            return state.appointmentsAccessCodes
        }
    },
    actions: {
        fetchAppointmentsAccessCodes(state: any) {
            return new Promise((resolve, reject) => {
                fetch(process.env.BASE_URL + `api/appointmentsaccesscodes/`,
                    {
                        method: 'GET',
                        headers:
                        {
                            Authorization: `Bearer ${state.getters.token}`,
                        }
                    })
                    .then(res => res.json())
                    .then(appointmentsAccessCodes => {
                        state.commit('setAppointmentsAccessCodes', appointmentsAccessCodes)
                        resolve(appointmentsAccessCodes)
                    })
                    .catch(err => reject(err))
            })
        },
        validateAccessCode(state: any, enteredCode: string) {
            return new Promise((resolve, reject) => {
                fetch(`${process.env.BASE_URL}api/appointmentsaccesscodes/validate`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${state.getters.token}`,
                    },
                    body: JSON.stringify({ code: enteredCode }),
                })
                .then(res => res.json())
                .then(data => {
                    resolve(data.valid); // Assuming API returns { valid: true/false }
                })
                .catch(err => reject(err));
            });
        }
    
    }
}
