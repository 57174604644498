<template>
  <div>
    <Navbar></Navbar>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="content">
            <form @submit.prevent="editVetMeth($t)">
              <div class="content-body">
                <div class="header">
                  <h5 class="title is-5" v-if="getEditingVet">
                    {{ $t('UserData.PleaseEnterYourUserData') }}
                  </h5>
                  <h5 class="title is-5" v-if="!getEditingVet">
                    {{ $t('UserData.YourUserData') }}
                  </h5>
                </div>
                <div class="columns is-vcentered">
                  <div class="column is-one-fifth mb-0 is-flex is-align-items-center is-justify-content-center">
                    <div class = "image-container">
                      <figure class="image is-128x128 pointer" @click="selectFile">
                        <img class="is-rounded" :src="userImageURL" alt="click_to_upload">
                      </figure>
                      <p v-if="getEditingVet" style="text-align: center; font-size: 12px; margin-top: 0px;">{{ $t('UserData.PleaseClickPhotoToUpdate') }}</p>
                    </div>
                  <input type="file" class="is-hidden" ref="imageInput" @change="uploadImage">
                  </div>
                  <div class="column mb-0">
                    <div class="field">
                      <label class="label">{{ $t('UserData.Name') }}</label>
                      <div class="control">
                        <input :minlength='2' v-model="getEditedVetData.name" class="input" type="text"
                        :placeholder="$t('UserData.NamePlaceholder')" required :disabled="!getEditingVet">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column mt-0">
                    <div class="field">
                      <label class="label">{{ $t('UserData.Email') }}</label>
                      <div class="control">
                        <input v-model="getEditedVetData.email" class="input" type="email"
                        :placeholder="replaceSpecialCharacters($t('UserData.EmailPlaceholder'))" required :disabled="!getEditingVet">
                      </div>
                    </div>
                  </div>
                  <div class="column mt-0">
                    <div class="field">
                      <label class="label">{{ $t('UserData.PhoneNumber') }}</label>
                      <div class="control">
                        <input :minlength='6' v-model="getEditedVetData.phone" class="input" type="tel"
                        :placeholder="$t('UserData.PhoneNumberPlaceholder')" required :disabled="!getEditingVet">
                      </div>
                    </div>
                  </div>
                  <div class="column mt-0">
                    <div class="field">
                      <label class="label">{{ $t('UserData.DiscountPercentage') }}</label>
                      <div class="control">
                        <input v-model="getEditedVetData.discount" class="input" type="number" min="0" max="100" step="5"
                        :placeholder="$t('UserData.DiscountPlaceholder')" required :disabled="!getEditingVet">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field is-grouped is-grouped-right">
                  <p class="control"
                    v-if="getEditingVet">
                    <button type="submit" class="button is-primary">{{ $t('General.Save') }}</button>
                  </p>
                  <p class="control"
                    v-if="getEditingVet">
                    <a @click="cancelEditing" class="button is-light">
                      {{ $t('General.Cancel') }}
                    </a>
                  </p>
                  <p class="control"
                    v-if="!getEditingVet">
                    <a @click="iniVetEditing" class="button is-primary">
                      {{ $t('General.ModifyData') }}
                    </a>
                  </p>
                </div>
                <div v-if="!getHasAssociatedCalendar">
                  <div class="notification is-danger is-light is-small" v-html="$t('VetData.NoCalendarAssociated')" style="padding: 0.5rem; margin: 0.5rem 0;">
                  </div>
                  <div class="notification is-light is-small" v-html="$t('VetData.PressToAssociateCalendar')" style="padding: 0.5rem; margin: 0.5rem 0;">
                  </div>
                  <!-- Associate calendar button -->
                  <button type="button" class="button is-primary" @click="associateGoogleCalendar">
                    {{ $t('VetData.AssociateCalendar') }}
                  </button>
                </div>
                <div v-if="getHasAssociatedCalendar">
                  <div class="notification is-success is-light is-small" v-html="$t('VetData.CalendarAssociatedDetected')">
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Navbar from "../components/Navbar.vue";
import { Notyf } from "notyf";
import { useRoute } from 'vue-router';
import { changeLocale } from "@/main"; // Import the changeLocale function


const notyf = new Notyf()

export default defineComponent({
  name: "VetData",
  components: { Navbar },

  data() {
    return {
      componentName: "VetData",
      route: useRoute(),
      language: ''
    }
  },
  computed: {
    ...mapGetters(['getEditedVetData', 'userData', 'getEditingVet', 'getEditedInitialEmail', 'getHasAssociatedCalendar', 'getAccessType']),
    validPassword(): boolean {
      return (this.getEditedVetData.password === this.getEditedVetData.confirmation && this.getEditedVetData.password.length >= 6)
    },
    userImageURL(): string {
      const random = Math.round(99999 * Math.random());
      return process.env.BASE_URL + `api/users/image/${this.getEditedVetData.user_id}/?hash=${random}`;
    },
  },
  methods: {
    ...mapActions(['checkEmailIsTaken', 'editVet', 'iniEditedVet', 'iniVetEditing', 'endVetEditing',
                   'IniInitialEmail', 'logout', 'uploadUserImage', 'toggleShowingUserData', 'IniAccessType', 'removeVetCalendarAssociation']),

    replaceSpecialCharacters(text: string) {
      console.log('Original text:', text);
      // Regular expression to replace '%at%' with '@'
      const replacedText = text.replace('%at%', '@');
      console.log('Replaced text:', replacedText);
      return replacedText;
    },

    
    async editVetMeth($t : any) {
      console.log("editVetMeth")
      console.log(this.getEditedInitialEmail)
      console.log(this.getEditedVetData.email)

      const emailChanged = (this.getEditedInitialEmail != this.getEditedVetData.email)
      console.log(emailChanged)

      var checkEmailIsTaken: boolean = false;

      if (emailChanged)
      {
        checkEmailIsTaken = await this.checkEmailIsTaken(this.getEditedVetData.email)
        if (!checkEmailIsTaken) {
          const confirmLogout = confirm($t && $t('VetData.ConfirmEmailChange'));
          if (confirmLogout)
          {
            try
            {
              // If the email changed the calendar association is removed in the backend
              var editedVetId: number = await this.removeVetCalendarAssociation(this.getEditedVetData.vet_id)
              if (editedVetId)
              {
                notyf.success(this.$t('VetData.NotyfCalendarAssociationRemoved'))
              }
              
              editedVetId = await this.editVet(this.getEditedVetData)
              
              console.log(editedVetId)

              if (editedVetId)
              {
                this.endVetEditing()
                notyf.success(this.$t('UserData.NotyfDataEdited'))
                this.initializeEditedVet()
              }
            }
            catch (e)
            {
              notyf.error(this.$t('UserData.NotyfErrorUpdatingData'))
            }
            // Redirect the user to the login page
            this.toggleShowingUserData(true);
            this.logout()
            this.$router.push('/')
            return; // Stop further execution of the method
          }
          else
          {
            // If the user cancels, reset the email field to the initial value
            this.getEditedVetData.email = this.getEditedInitialEmail;
            return; // Stop further execution of the method
          }
        }
        else
        {
          notyf.error(this.$t('UserData.NotyfEmailInUse'))
        }
      }
      else
      {
        // If the email didn't change the update of the data is executed and the user is not redirected to login
        try
        {
          var editedVetId: number = await this.editVet(this.getEditedVetData)
          console.log(editedVetId)
          if (editedVetId)
          {
            this.endVetEditing()
            notyf.success(this.$t('UserData.NotyfDataEdited'))
            this.initializeEditedVet()
          }
        }
        catch (e)
        {
          notyf.error(this.$t('UserData.NotyfErrorUpdatingData'))
        }
      }
      return;
    },

    cancelEditing() {
      this.endVetEditing()
      this.clearFormVet()
    },

    async clearFormVet() {
      // This function seems not to be doing anything from where it is called
      // redundant ?
      const resultIniVet = await this.iniEditedVet(this.userData.email);
      this.IniInitialEmail(resultIniVet.email)
    },

    async initializeEditedVet() {
      console.log("initializeEditedVet")
      console.log(this.userData.email);
      const resultIniVet = await this.iniEditedVet(this.userData.email);
      this.IniInitialEmail(this.userData.email)
      console.log(resultIniVet);
      console.log(this.getEditedVetData);
      console.log(this.getEditedVetData.user_id);

    },

    selectFile()
    {
      // Ensure that this.$refs.imageInput is not undefined before calling click()
      const imageInputRef = this.$refs.imageInput as HTMLInputElement;
      if (imageInputRef) {
        imageInputRef.click();
      } else {
        console.error("Image input ref is not defined.");
      }
    },
    async uploadImage()
    {
      console.log("uploadImage method is called")
      const imageInputRef = this.$refs.imageInput as HTMLInputElement;
      const file = imageInputRef?.files?.[0];
      
      if (!file) {
        console.error("No file selected.");
        return;
      }
      try {
        this.uploadUserImage({ user_id: this.getEditedVetData.user_id, file: file});
        this.initializeEditedVet();
      } catch (e) {
        notyf.error(this.$t('UserData.NotyfErrorUploadingPhoto'));
      }
    },
    associateGoogleCalendar() {
      const aurinkoClientId = process.env.VUE_APP_AURINKO_APP_CLIENT_ID;
      const serviceType = 'Google';
      const scopes = 'Calendar.ReadWrite';
      const responseType = 'token';
      const returnUrl = process.env.FULL_BASE_URL + 'auth/callback'; //final callback url, Not Google's Oauth2 callback
      const vet_id = this.getEditedVetData.vet_id;
      const language = this.language;
      const accessType = this.getAccessType;
      const state = `vet_id:${vet_id} lang:${language} access:${accessType}`;
      const recycle = 'true';
      const authEmail = this.getEditedVetData.email;
      const url = `https://api.aurinko.io/v1/auth/authorize?clientId=${aurinkoClientId}&serviceType=${serviceType}&scopes=${scopes}&responseType=${responseType}&returnUrl=${returnUrl}&state=${state}&recycle=${recycle}&authEmail=${authEmail}`;     
      window.location.href = url;
    }
  },
  watch: {},
  mounted()
  {
    this.toggleShowingUserData(false);
  },
  beforeMount() {
    this.language = this.route.query.lang as string;
    const accessType = this.route.query.access as string;

    if (this.language)
    {
      try
      {
        changeLocale(this.language);
      }
      catch (error)
      {
        console.error('Failed to change language:', error);
      }
    }
    else
    {
      console.log("lang does not exist");
    }
    if (accessType)
    {
      try
      {
        this.IniAccessType(accessType);
      }
      catch (error)
      {
        console.error('Failed to set access type:', error);
      }
    }
    else
    {
      console.log("access type does not exist");
    }
    this.toggleShowingUserData(false);
    this.initializeEditedVet();
  }

})
</script>

<style scoped>
.content-body {
  margin-top: 10px;
}

.content-body {
  margin-top: 10px;
}
</style>