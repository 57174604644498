import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Customer from '../views/Customer.vue'
import Appointment from "../views/Appointment.vue"
import RequestSent from "../views/RequestSent.vue"
import RequestConfirmed from "../views/RequestConfirmed.vue"
import ConfirmAppointment from "../views/ConfirmAppointment.vue"
import JitsiIframe from "../components/JitsiIframe.vue"
import Management from "../views/Management.vue"
import Vet from "../views/Vet.vue"
import CustomerRegistration from "../views/CustomerRegistration.vue"
import CustomerData from "../views/CustomerData.vue"
import ManagerData from "../views/ManagerData.vue"
import VetData from "../views/VetData.vue"
import Login from "../views/Login.vue"
import AuthCallback from "../views/AuthCallback.vue"
import ResetPassword from '@/views/ResetPassword.vue'
//import DataPrivacyPolicy from '@/views/DataPrivacyPolicy.vue'
import NewPassword from '@/views/NewPassword.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Login',
        component: Login,
        meta: { title: 'Login' }
    },
    {
        path: '/registration',
        name: 'Registration',
        component: CustomerRegistration,
        meta: { title: 'New Customer Registration' }
    },
    {
        path: '/resetpassword',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: { title: 'Reset Password' }
    },
    /*{
        path: '/dataprivacypolicy',
        name: 'DataPrivacyPolicy',
        component: DataPrivacyPolicy,
        meta: { title: 'Data Privacy Policy' }
    },*/
    {
        path: '/newpassword',
        name: 'NewPassword',
        component: NewPassword,
        meta: { title: 'Set New Password' }
    },
    {
        path: '/customer',
        name: 'Customer',
        meta: { requiresAuth: true, title: 'Customer Mode - Schedule Appointment' },
        component: Customer
    },
    {
        path: '/customerdata',
        name: 'CustomerData',
        component: CustomerData,
        meta: { title: 'Customer Data' }
    },
    {
        path: '/vetdata',
        name: 'VetData',
        component: VetData,
        meta: { title: 'Vet Data' }
    },
    {
        path: '/auth/callback',
        name: 'AuthCallback',
        component: AuthCallback,
        meta: { title: 'Auth CallBack' },
        beforeEnter: (to, from, next) => {
          // Process the callback parameters here
          console.log('beforeEnter');
          console.log(to.query);
          const { token, state } = to.query;
          // Handle the token and state as needed
          next();
        }
    },
    {
        path: '/managerdata',
        name: 'ManagerData',
        component: ManagerData,
        meta: { title: 'Manager Data' }
    },
    {
        path: '/appointment/:pin?',
        name: 'Appointment',
        component: Appointment,
        meta: { title: 'Appointment' }
    },
    {
        path: '/requestsent/:payment_hash',
        name: 'Request Sent',
        component: RequestSent,
        meta: { title: 'Appointment Request Sent' }
    },
    {
        path: '/requestconfirmed/',
        name: 'Request Confirmed',
        component: RequestConfirmed,
        meta: { title: 'Appointment Request Confirmed' }
    },
    {
        path: '/confirmappointment/:hash',
        name: 'Appointment Confirmation',
        component: ConfirmAppointment,
        meta: { title: 'Appointment Confirmation' }
    }, {
        path: '/manager',
        name: 'Management',
        meta: { requiresAuth: true, title: 'Manager Mode' },
        component: Management,
    },
    {
        path: '/vet',
        name: 'Vet',
        meta: { requiresAuth: true, title: 'Vet Mode' },
        component: Vet,
    },
    /*{
      path: '/about',
      name: 'About',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
    }*/
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {

    // Update the document title
    document.title = to.meta.title as string || 'Wise Vet Live';

    // instead of having to check every route record with
    // to.matched.some(record => record.meta.requiresAuth)
    // if (to.meta.requiresAuth && !localStorage.getItem('token')) {
    if (to.meta.requiresAuth &&
        from.name != 'Login' &&
        from.name != 'CustomerData' &&
        from.name != 'Request Confirmed' &&
        from.name != 'VetData' &&
        from.name != 'ManagerData')
    {
        // if this route requires auth
        // clear token and redirect to login page.
        localStorage.clear()
        next({
            path: '/',
            query: { redirect: to.fullPath }, // Save location for post-login
        });
    }

    else
    {
        next(); // Proceed to the next route
    }
})

/*router.afterEach((to) => {

    // Ensure the document title is up-to-date before tracking the event
    const pageTitle = document.title || 'Wise Vet Live';

    // Track page view in Google Analytics
    window.gtag('event', 'page_view', {
      page_path: to.fullPath,
      page_title: pageTitle,
    });
  });
*/

export default router
