<template>
  <div>
    <Navbar></Navbar>
    <section class="hero">
      <div class="hero-body">
        <div class="container">
          <div class="content">
            <form @submit.prevent="editCustomerMeth" v-if="!getShowingAppointmentData">
              <div class="content-body">
                <form @submit.prevent="editCustomerMeth" v-if="!getShowingPetRecord">
                  <div class="header">
                    <h5 class="title is-5" v-if="getEditingCustomer">
                      {{ $t('CustomerData.PleaseModifyYourCustomerData') }}
                    </h5>
                    <h5 class="title is-5" v-if="!getEditingCustomer">
                      {{ $t('CustomerData.YourCustomerData') }}
                    </h5>
                  </div>
                  <div class="columns">
                    <div class="column is-half">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Name') }}</label>
                        <div class="control">
                          <input :minlength='2' v-model="getEditedCustomerData.name" class="input" type="text"
                          :placeholder="$t('CustomerData.NamePlaceholder')" required :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Email') }}</label>
                        <div class="control">
                          <input v-model="getEditedCustomerData.email" class="input" type="email"
                            :placeholder="replaceSpecialCharacters($t('CustomerData.EmailPlaceholder'))" required :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('UserData.PhoneNumber') }}</label>
                        <div class="control">
                          <input :minlength='6' v-model="getEditedCustomerData.phone" class="input" type="tel"
                          :placeholder="$t('UserData.PhoneNumberPlaceholder')" required :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.VATNumber') }}</label>
                        <div class="control">
                          <input v-model="getEditedCustomerData.tax_id" class="input" type="text"
                          :placeholder="$t('CustomerData.VATNumberPlaceholder')" required :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-6" v-if="getEditingCustomer">
                      <div class="notification is-light is-small" v-html="$t('CustomerData.PostalCodeSearchNote')">
                      </div>
                      <!-- Tooltip version
                      <span class="icon has-tooltip-arrow has-tooltip-right" :data-tooltip="$t('CustomerData.PostalCodeSearchNote')">
                          <i class="fas fa-info-circle has-text-success"></i>
                      </span>
                      -->
                    </div>
                    <div class="column is-2">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.PostalCode') }}</label>
                        <div class="control">
                          <input v-model="getEditedCustomerData.postal_code" class="input" type="text"
                          :placeholder="$t('CustomerData.PostalCodePlaceholder')" required
                            :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    <div class="column is-4">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.City') }}</label>
                        <div class="control" :class="{ 'is-loading': postalCodeIsLoading }">
                          <input v-model="getEditedCustomerData.city" class="input" type="text"
                          :placeholder="$t('CustomerData.CityPlaceholder')" required
                            :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="columns">
                    <div class="column is-half">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Address') }}</label>
                        <div class="control">
                          <input v-model="getEditedCustomerData.address" class="input" type="text"
                          :placeholder="$t('CustomerData.AddressPlaceholder')" required
                            :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    <div class="column is-one-quarter">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.State') }}</label>
                        <div class="control">
                          <input v-model="getEditedCustomerData.state" class="input" type="text"
                          :placeholder="$t('CustomerData.StatePlaceholder')" required
                            :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    <div class="column is-one-quarter">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.Country') }}</label>
                        <div class="control">
                          <input v-model="getEditedCustomerData.country" class="input" type="text"
                          :placeholder="$t('CustomerData.CountryPlaceholder')" required
                            :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    <!--Vet Center
                    <div class="column">
                      <div class="field">
                        <label class="label">{{ $t('CustomerData.VetCenter') }}</label>
                        <div class="control">
                          <input v-model="getEditedCustomerData.vet_center" class="input" type="text"
                          :placeholder="$t('CustomerData.VetCenterPlaceholder')" required
                            :disabled="!getEditingCustomer">
                        </div>
                      </div>
                    </div>
                    -->
                  </div>
                </form>
              </div>
              <div class="header">
                <h5 class="title is-6 mt-4" v-if="!addingPet && !getEditedCustEditingPet && !getShowingPetRecord">
                  {{ $t('CustomerData.YourPets') }}
                </h5>
              </div>
              <div class="content-body">
                <div class="table-container">
                  <table class="table" v-if="!addingPet && !getEditedCustEditingPet && !getShowingPetRecord">
                    <thead>
                      <tr>
                        <th class="sticky-column">{{ $t('PetData.PetName') }}</th>
                        <th class="">{{ $t('PetData.Species') }}</th>
                        <th class="">{{ $t('PetData.Breed') }}</th>
                        <th class="">{{ $t('PetData.Gender') }}</th>
                        <th class="">{{ $t('PetData.Birth') }}</th>
                        <th class="">{{ $t('PetData.Age') }}</th>
                        <th class="">{{ $t('PetData.FertileStatus') }}</th>
                        <th class="">{{ $t('PetData.Weight') }}</th>
                        <th class="" v-if="considerInsurance">{{ $t('PetData.InsurancePolicy') }}</th>
                        <th>{{ $t('General.Operations') }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="pet of getEditedCustomerPets" :key="pet.pet_id">
                        <CustDataPetTableRow :pet-data="pet" :language="language"></CustDataPetTableRow>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="content-body">
                <div class="header is-flex is-justify-content-space-between" v-if="!addingPet && !getEditedCustEditingPet
              && getEditingCustomer && !getShowingPetRecord">
                  <div>
                    <button class="button is-primary" @click="toggleAddingPet"><strong>{{ $t('CustomerData.NewPet') }}</strong></button>
                  </div>
                </div>
              </div>
              <div class="content-body">
                <div class="field is-grouped is-grouped-right">
                  <p class="control"
                    v-if="!addingPet && !getEditedCustEditingPet && getEditingCustomer && !getShowingPetRecord">
                    <button type="submit" class="button is-primary">{{ $t('General.Save') }}</button>
                  </p>
                  <p class="control"
                    v-if="!addingPet && !getEditedCustEditingPet && getEditingCustomer && !getShowingPetRecord">
                    <a @click="cancelEditing" class="button is-light">
                      {{ $t('General.Cancel') }}
                    </a>
                  </p>
                  <p class="control"
                    v-if="!addingPet && !getEditedCustEditingPet && !getEditingCustomer && !getShowingPetRecord && !getCustDetailing">
                    <a @click="iniCustomerEditing" class="button is-primary">
                      {{ $t('General.ModifyData') }}
                    </a>
                  </p>
                  <p class="control" v-if="getCustDetailing && !getShowingPetRecord">
                    <a @click="endCustDetailing" class="button is-primary">
                      {{ $t('General.Back') }}
                    </a>
                  </p>
                </div>
              </div>
              <div class="content-body">
                <form @submit.prevent="addPetMeth" v-if="addingPet">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('CustomerData.AddNewPet') }}
                    </h5>
                  </div>
                  <CustDataPetDataForm ref="refNewPetDataForm"></CustDataPetDataForm>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control">
                      <button type="submit" class="button is-primary">{{ $t('General.Add') }}</button>
                    </p>
                    <p class="control">
                      <a @click="toggleAddingPet" class="button is-light">
                        {{ $t('General.Cancel') }}
                      </a>
                    </p>
                  </div>
                </form>
              </div>
              <div class="content-body">
                <form @submit.prevent="editPetMeth" v-if="getEditedCustEditingPet">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('CustomerData.EditPet') }}
                    </h5>
                  </div>
                  <CustDataPetDataForm ref="refEditedPetDataForm"></CustDataPetDataForm>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control">
                      <button type="submit" class="button is-primary">{{ $t('General.Edit') }}</button>
                    </p>
                    <p class="control">
                      <a @click="cancelEditPet" class="button is-light">
                        {{ $t('General.Cancel') }}
                      </a>
                    </p>
                  </div>
                </form>
              </div>
              <div class="content-body">
                <form @submit.prevent="endShowingPetRecordMeth" v-if="getShowingPetRecord">
                  <div class="header">
                    <h5 class="title is-5">
                      {{ $t('PetData.PetHistoricalRecord') }}
                    </h5>
                  </div>
                  <CustDataPetDataForm ref="refPetRecordDataForm"></CustDataPetDataForm>
                  <div class="field is-grouped is-grouped-right">
                    <p class="control">
                      <button type="submit" class="button is-primary">{{ $t('General.Back') }}</button>
                    </p>
                  </div>
                </form>
              </div>
            </form>
            <AppointmentDataForm v-if="getShowingAppointmentData"></AppointmentDataForm>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import Navbar from "../components/Navbar.vue";
import { Notyf } from "notyf";
import CustDataPetTableRow from "../components/CustDataPetTableRow.vue";
import CustDataPetDataForm from "../components/CustDataPetDataForm.vue";
import AppointmentDataForm from "../components/AppointmentDataForm.vue";
import { useRoute } from 'vue-router';
import { changeLocale } from "@/main"; // Import the changeLocale function

const notyf = new Notyf()

export default defineComponent({
  name: "CustomerData",
  components: { CustDataPetTableRow, Navbar, CustDataPetDataForm, AppointmentDataForm },

  data() {
    return {
      componentName: "CustomerData",
      addingPet: false,
      otherSpecies: false,
      route: useRoute(),
      language: '',
      postalCodeIsLoading: false,
      considerInsurance: process.env.VUE_APP_CONSIDER_INSURANCE === "true"
    }
  },
  computed: {
    ...mapGetters(['getEditedCustomerData', 'getEditedCustomerPets', 'getEditedCustNewPetData', 'getEditedCustBreeds',
      'getEditedCustEditingPet', 'getEditedCustInitialPetData', 'userData', 'getEditingCustomer', 'getShowingPetRecord',
      'getEditedInitialEmail', 'getEditedCustPetsToDelete', 'getCustDetailing', 'getShowingAppointmentData', 'getCustomerHasPets', 'getAccessType']),
    
      validPassword(): boolean {
      return (this.getEditedCustomerData.password === this.getEditedCustomerData.confirmation && this.getEditedCustomerData.password.length >= 6)
    },
  },
  methods: {
    ...mapActions(['addPetEditedCust', 'iniEditedCustNewPet', 'checkEmailIsTaken', 'editCustomer', 'createEditedCustPet',
      'updateEditedCustPet', 'iniEditedCustomer', 'iniEditedCustomerPets', 'updateEditedCustBreeds', 'fetchPets',
      'removePetEditedCustomer', 'toggleEditedCustPetEditing', 'editPetEditedCust', 'iniCustomerEditing', 'endCustomerEditing',
      'toggleShowingPetRecord', 'deleteEditedCustPet', 'toggleShowingUserData', 'toggleCustDetailing', 'IniInitialEmail', 'logout', 'IniAccessType']),

    replaceSpecialCharacters(text: string) {
      console.log('Original text:', text);
      // Regular expression to replace '%at%' with '@'
      const replacedText = text.replace('%at%', '@');
      console.log('Replaced text:', replacedText);
      return replacedText;
    },

    async editCustomerMeth()
    {
      console.log("editCustomerMeth")
      console.log(this.getEditedInitialEmail)
      console.log(this.getEditedCustomerData.email)
      // verify if all fields are filled
      if (!this.getEditedCustomerData.name || !this.getEditedCustomerData.email || !this.getEditedCustomerData.phone ||
          !this.getEditedCustomerData.tax_id || !this.getEditedCustomerData.address || !this.getEditedCustomerData.postal_code ||
          !this.getEditedCustomerData.city || !this.getEditedCustomerData.state || !this.getEditedCustomerData.country)
      {
        notyf.error(this.$t('CustomerData.NotyfFillAllFields'))
        return;
      }

      const emailChanged = (this.getEditedInitialEmail != this.getEditedCustomerData.email)
      console.log(emailChanged)

      var checkEmailIsTaken: boolean = false;

      if (emailChanged)
      {
        checkEmailIsTaken = await this.checkEmailIsTaken(this.getEditedCustomerData.email)
        
        if (!checkEmailIsTaken)
        {
          const confirmLogout = confirm(this.$t('UserData.ConfirmEmailChange'));
          if (confirmLogout)
          {
            try
            {
              var editedCustomerId: number = await this.editCustomer(this.getEditedCustomerData)
              console.log(editedCustomerId)

              if (editedCustomerId)
              {
                //Edit Pets in the customer
                for (var editedPetData of this.getEditedCustomerPets)
                {
                  editedPetData.user_id = editedCustomerId;
                  if (editedPetData.pet_id == 0)
                  {
                    //create new added pets
                    await this.createEditedCustPet(editedPetData)
                  }
                  else
                  {
                    //update existing pets
                    await this.updateEditedCustPet(editedPetData)
                  }
                }
                //delete removed pets
                for (var petId of this.getEditedCustPetsToDelete)
                {
                  await this.deleteEditedCustPet(petId);
                }
                this.endCustomerEditing()
                notyf.success(this.$t('CustomerData.NotyfDataEdited'))
              }
            }
            catch (e)
            {
              notyf.error(this.$t('CustomerData.NotyfErrorUpdatingData'))
            }
            // Redirect the user to the login page
            this.toggleShowingUserData(true);
            this.logout()
            this.$router.push('/')
      
            return; // Stop further execution of the method
          }
          else
          {
            // If the user cancels, reset the email field to the initial value
            this.getEditedCustomerData.email = this.getEditedInitialEmail;
            return; // Stop further execution of the method
          }
        }
        else {
          notyf.error(this.$t('CustomerData.NotyfEmailInUse'))
        }
      }
      else
      {
        try
        {
          var editedCustomerId: number = await this.editCustomer(this.getEditedCustomerData)
          console.log(editedCustomerId)

          if (editedCustomerId) {
            //Edit Pets in the customer
            for (var editedPetData of this.getEditedCustomerPets)
            {
              editedPetData.user_id = editedCustomerId;
              if (editedPetData.pet_id == 0)
              {
                //create new added pets
                await this.createEditedCustPet(editedPetData)
              }
              else
              {
                //update existing pets
                await this.updateEditedCustPet(editedPetData)
              }
            }
            //delete removed pets
            for (var petId of this.getEditedCustPetsToDelete)
            {
              await this.deleteEditedCustPet(petId);
            }
            this.endCustomerEditing()
            notyf.success(this.$t('CustomerData.NotyfDataEdited'))
          }
        }
        catch (e)
        {
          notyf.error(this.$t('CustomerData.NotyfErrorUpdatingData'))
        }
      }
    },

    cancelEditing() {
      this.endCustomerEditing()
      this.clearFormCustomer()
      this.initializeEditedCustomer()
    },

    async addPetMeth() {
      this.toggleAddingPet();
      console.log(this.getEditedCustomerPets)
      this.getEditedCustNewPetData.pet_id = 0
      this.addPetEditedCust(this.getEditedCustNewPetData)
      console.log(this.getEditedCustomerPets);
      // Cleans the form with pet data using method in child component
      // this.clearFormPet()
      (this.$refs.refNewPetDataForm as any).clearFormPet();
      notyf.success(this.$t('CustomerData.NotyfNewPetAdded'))
    },


    async editPetMeth() {
      console.log(this.getEditedCustomerPets)
      console.log(this.getEditedCustNewPetData)
      console.log('editPetMeth')
      this.editPetEditedCust(this.getEditedCustNewPetData)
      console.log(this.getEditedCustomerPets)
      this.toggleEditedCustPetEditing(true);
      // Cleans the form with pet data using method in child component
      // this.clearFormPet()
      (this.$refs.refEditedPetDataForm as any).clearFormPet();
      notyf.success(this.$t('CustomerData.NotyfPetModified'))
    },

    async endShowingPetRecordMeth() {
      console.log('endShowingPetRecordMeth')
      this.toggleShowingPetRecord(true);
      // Cleans the form with pet history data using method in child component
      // this.clearFormPet()
      (this.$refs.refPetRecordDataForm as any).clearFormPet();
    },

    cancelEditPet() {
      console.log('cancelPetMeth');
      (this.$refs.refEditedPetDataForm as any).clearFormPet();
      this.toggleEditedCustPetEditing(true)
    },


    async clearFormCustomer() {
      // This function seems not to be doing anything from where it is called
      // redundant ?
      const resultIniCust = await this.iniEditedCustomer(this.userData.email);
      this.IniInitialEmail(resultIniCust.email)
        
    },


    toggleAddingPet() {
      this.addingPet = !this.addingPet
    },


    async initializeEditedCustomer() {

      console.log("initializeEditedCustomer")
      console.log(this.getCustDetailing);
      if (!this.getCustDetailing) {
        console.log(this.userData.email);
        const resultIniCust = await this.iniEditedCustomer(this.userData.email);
        this.IniInitialEmail(resultIniCust.email)
        console.log(resultIniCust);
      }
      console.log(this.getEditedCustomerData);
      console.log(this.getEditedCustomerData.user_id);
      const resultIniPets = await this.iniEditedCustomerPets(this.getEditedCustomerData.user_id);
      console.log(resultIniPets);
      console.log(this.getEditedCustomerPets);

    },

    endCustDetailing() {
      this.toggleShowingUserData(true);
      this.toggleCustDetailing(true);
      this.clearFormCustomer();
      this.$router.go(-1);
    },

    fetchDetailsByPostalCode(postalCode: string)
    {
      const postalCodePattern: { [key: string]: RegExp } =
      {
        'United States': /^\d{5}$/,
        'Portugal': /^\d{4}-\d{3}$/,
        'Brasil': /^\d{5}-\d{3}$/
      };
            
      switch (process.env.COUNTRY) {
          case 'United States':
          if (!postalCodePattern['United States'].test(postalCode))
            {
              this.getEditedCustomerData.city = this.$t('CustomerData.InvalidPostalCode');
              this.getEditedCustomerData.state = '';
              this.getEditedCustomerData.address = '';
              this.getEditedCustomerData.country = process.env.COUNTRY;
              return;
            }
            this.postalCodeIsLoading = true;
            fetch(`https://api.zippopotam.us/us/${postalCode}`)
              .then(response => response.json())
              .then(data => {
                if(data.erro)
                {
                  this.getEditedCustomerData.city = this.$t('CustomerData.InvalidPostalCode');
                  this.getEditedCustomerData.state = '';
                  this.getEditedCustomerData.address = '';
                  this.getEditedCustomerData.country = process.env.COUNTRY;
                }
                else
                {
                  this.getEditedCustomerData.city = data.places[0]['place name'];
                  this.getEditedCustomerData.state = data.places[0]['state'];
                  this.getEditedCustomerData.address = '';
                  this.getEditedCustomerData.country = process.env.COUNTRY;
                }
              })
              .catch(error => {
                console.error('Error fetching details:', error);
              })
              .finally(() => {
                this.postalCodeIsLoading = false;
              });
              break;
          case 'Portugal':
            if (!postalCodePattern['Portugal'].test(postalCode))
            {
              this.getEditedCustomerData.city = this.$t('CustomerData.InvalidPostalCode');
              this.getEditedCustomerData.state = '';
              this.getEditedCustomerData.address = '';
              this.getEditedCustomerData.country = process.env.COUNTRY;
              return;
            }
            this.postalCodeIsLoading = true;
            /* Using GeoAPI
            fetch(`https://json.geoapi.pt/cp/${postalCode}`)
              .then(response => response.json())
              .then(data => {
                this.getEditedCustomerData.city = data.Localidade;;
                this.getEditedCustomerData.state = data.Distrito;
                this.getEditedCustomerData.address = data.partes[0].Artéria;
                this.getEditedCustomerData.country = process.env.COUNTRY;
              })
              .catch(error => {
                console.error('Error fetching details:', error);
              })
              .finally(() => {
                this.postalCodeIsLoading = false;
              });
            */
            /* Using CTT Codigo Postal API 30 requests/min, 10000/month*/
            fetch(`https://www.cttcodigopostal.pt/api/v1/297a6f4ff9fc4acc9d4e65e260683d13/${postalCode}`)
            .then(response => response.json())
            .then(data => {
              this.getEditedCustomerData.city = data[0].localidade;
              this.getEditedCustomerData.state = data[0].distrito;
              this.getEditedCustomerData.address = data[0].morada;
              this.getEditedCustomerData.country = process.env.COUNTRY;
            })
            .catch(error => {
              console.error('Error fetching details:', error);
            })
            .finally(() => {
              this.postalCodeIsLoading = false;
            });
            

            break;
          case 'Brasil':
            if (!postalCodePattern['Brasil'].test(postalCode))
            {
              this.getEditedCustomerData.city = this.$t('CustomerData.InvalidPostalCode');
              this.getEditedCustomerData.state = '';
              this.getEditedCustomerData.address = '';
              this.getEditedCustomerData.country = process.env.COUNTRY;
              return;
            }
            this.postalCodeIsLoading = true;
            const cleanedPostalCode = postalCode.replace(/-/g, '');
            
            /* With Brasil API
            fetch(`https://brasilapi.com.br/api/cep/v1/${cleanedPostalCode}`)
              .then(response => response.json())
              .then(data => {
                this.getEditedCustomerData.city = data.city;
                this.getEditedCustomerData.state = data.state;
                this.getEditedCustomerData.address = data.street;
                this.getEditedCustomerData.country = process.env.COUNTRY;
              })
              .catch(error => {
                console.error('Error fetching details:', error);
              })
              .finally(() => {
                this.postalCodeIsLoading = false;
              });
              break;
            */

            /* With ViaCEP API 300 requests/hour */
            fetch(`https://viacep.com.br/ws/${cleanedPostalCode}/json/`)
              .then(response => response.json())
              .then(data => {
                if(data.erro)
                {
                  this.getEditedCustomerData.city = this.$t('CustomerData.InvalidPostalCode');
                  this.getEditedCustomerData.state = '';
                  this.getEditedCustomerData.address = '';
                  this.getEditedCustomerData.country = process.env.COUNTRY;
                }
                else
                {
                  this.getEditedCustomerData.city = data.localidade;
                  this.getEditedCustomerData.state = data.estado;
                  this.getEditedCustomerData.address = data.logradouro + ', Bairro ' + data.bairro;
                  this.getEditedCustomerData.country = process.env.COUNTRY;
                }
              })
              .catch(error => {
                console.error('Error fetching details:', error);
              })
              .finally(() => {
                this.postalCodeIsLoading = false;
              });
              break;
          default:
            throw new Error('Unsupported country');
        }
    }
  },
  watch:
  {
    'getEditedCustomerData.postal_code'(newPostalCode) {
      this.fetchDetailsByPostalCode(newPostalCode);
    }

  },
  beforeMount() {
    this.language = this.route.query.lang as string;
    const accessType = this.route.query.access as string;

    if (this.language)
    {
      try
      {
        changeLocale(this.language);
      }
      catch (error)
      {
        console.error('Failed to change language:', error);
      }
    }
    else
    {
      console.log("lang does not exist");
    }
    if (accessType)
    {
      try
      {
        this.IniAccessType(accessType);
      }
      catch (error)
      {
        console.error('Failed to set access type:', error);
      }
    }
    else
    {
      console.log("access type does not exist");
    }
    this.initializeEditedCustomer();
  },
  mounted() {
    if(!this.getCustomerHasPets && this.getAccessType == 'customer')
    {
      this.iniCustomerEditing();
      this.toggleAddingPet();
    }
    this.toggleShowingUserData(false);
  }

})
</script>

<style scoped>
.content-body {
  margin-top: 10px;
}

.content-body {
  margin-top: 10px;
}

.table-container {
  overflow-x: auto;
}

table {
  border-collapse: separate;
  min-width: 800px;
}

.sticky-column {
  position: sticky;
  max-width: 150px; /* Ensure it doesn't expand beyond this width */
  left: 0;
  background: #fff; /* Match table background */
  z-index: 2;
  border-right: 1px solid #ddd; 
  border-left: 1px solid #ddd;
  will-change: left; /* Optimize performance */
} 

</style>