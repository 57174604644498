<template>
  <nav class="navbar is-transparent" role="navigation" aria-label="main navigation">
    
    <!-- navbar-brand: the left side of the navbar, always visible, which usually contains the logo and optionally some links or icons -->
    <div class="navbar-brand">
      <a class="navbar-item">
        <img :src="iconPath" alt="Wise Vet Live" height="100%">
      </a>
      <!-- Home icon -->
      <a @click.prevent="goToLandingScreen" class="navbar-item" v-if="getAccessType != 'newCustomer'">
        <span class="icon-text has-text-success">
          <span class="icon">
            <i class="fas fa-home fa-border"></i>
          </span>
          <span>{{ $t('Navbar.Start') }}</span> <!-- Text "Home" -->
        </span>
      </a>
      <!--<div class="navbar-item">
        {{ getAccessType }}
      </div>
      -->
      <!-- navbar-burger: the hamburger icon, which toggles the navbar menu on touch devices -->
      <a role="button" class="navbar-burger" @click="toggleNavbar" :class="{ 'is-active': isNavbarOpen }" aria-label="menu" aria-expanded="false" data-target="navMenu">
        <span></span>
        <span></span>
        <span></span>
      </a>
    </div>

    <!-- navbar-menu: the right side of the navbar, hidden on touch devices, visible on desktop -->
    <div id="navMenu" class="navbar-menu" :class="{ 'is-active': isNavbarOpen }">
      
      <!-- navbar-start: the left part of the menu, which appears next to the navbar brand on desktop -->
      <div class="navbar-start buttons-container">
        <!--        <router-link to="/backend" class="navbar-item" href="/">-->
        <!--          Backend-->
        <!--        </router-link>-->
      </div>

      <!-- navbar-end: the right part of the menu, which appears on desktop -->
      <div class="navbar-end buttons-container" v-if="getAccessType != 'newCustomer'">
        <!-- Veterinarians button for Manager Mode -->
        <div class="navbar-item" v-if="getAccessType == 'manager' && getMngMode != 'vets' && !getShowingUserData && !getCustDetailing && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="switchManagementMode('vets')" class="button is-primary">
                {{ $t('Navbar.Veterinarians') }}
              </a>
            </p>
          </div>
        </div>
        <!-- Managers button for Manager Mode -->
        <div class="navbar-item" v-if="getAccessType == 'manager' && getMngMode != 'managers' && !getShowingUserData && !getCustDetailing && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="switchManagementMode('managers')" class="button is-primary">
                {{ $t('Navbar.Managers') }}
              </a>
            </p>
          </div>
        </div>
        <!-- Customers button for Manager Mode -->
        <div class="navbar-item"
          v-if="getAccessType == 'manager' && getMngMode != 'customers' && !getShowingUserData && !getCustDetailing && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="switchManagementMode('customers')" class="button is-primary">
                {{ $t('Navbar.Customers') }}
              </a>
            </p>
          </div>
        </div>
        <!-- Appointments button for Manager Mode -->
        <div class="navbar-item"
          v-if="getAccessType == 'manager' && getMngMode != 'appointments' && !getShowingUserData && !getCustDetailing && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="switchManagementMode('appointments')" class="button is-primary">
                {{ $t('Navbar.Appointments') }}
              </a>
            </p>
          </div>
        </div>
        
        <!-- Products button for Manager Mode -->
        <div class="navbar-item"
          v-if="getAccessType == 'manager' && getMngMode != 'products' && !getShowingUserData && !getCustDetailing && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="switchManagementMode('products')" class="button is-primary">
                {{ $t('Navbar.Products') }}
              </a>
            </p>
          </div>
        </div>

        <!-- Appointments Access Codes button for Manager Mode -->
        <div class="navbar-item"
          v-if="useAppointsAccessCodes && getAccessType == 'manager' && getMngMode != 'appointments_access_codes' && !getShowingUserData && !getCustDetailing && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="switchManagementMode('appointments_access_codes')" class="button is-primary">
                {{ $t('Navbar.AppointAccessCodes') }}
              </a>
            </p>
          </div>
        </div>


        
        <!-- Avail. Config button for Vet Mode -->
        <div class="navbar-item"
          v-if="getAccessType == 'vet' && getVetMode != 'availabilityconfig' && !getShowingUserData && !getCustDetailing && getHasAssociatedCalendar && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="switchVetMode('availabilityconfig')" class="button is-primary">
                {{ $t('Navbar.AvailConfig') }}
              </a>
            </p>
          </div>
        </div>
        <!-- Avail. Slots button for Vet Mode -->
        <div class="navbar-item"
          v-if="getAccessType == 'vet' && getVetMode != 'availabilityslots' && !getShowingUserData && !getCustDetailing && getHasAssociatedCalendar && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="switchVetMode('availabilityslots')" class="button is-primary">
                {{ $t('Navbar.AvailSlots') }}
              </a>
            </p>
          </div>
        </div>
        <!-- Customers button for Vet Mode
        <div class="navbar-item"
          v-if="getAccessType == 'vet' && getVetMode != 'customers' && !getShowingUserData && !getCustDetailing && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="switchVetMode('customers')" class="button is-primary">
                {{ $t('Navbar.Customers') }}
              </a>
            </p>
          </div>
        </div>
        -->
        <!-- Appointments button for Vet Mode -->
        <div class="navbar-item"
          v-if="getAccessType == 'vet' && getVetMode != 'appointments' && !getShowingUserData && !getCustDetailing && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="switchVetMode('appointments')" class="button is-primary">
                {{ $t('Navbar.Appointments') }}
              </a>
            </p>
          </div>
        </div>
        <div class="navbar-item" v-if="!getShowingUserData && !getCustDetailing && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="showUserdata" class="button is-primary">
                {{ $t('Navbar.MyData') }}
              </a>
            </p>
          </div>
        </div>
        <div class="navbar-item" v-if="getShowingUserData && !getShowingPetRecord && !getCustDetailing && !getEditingCustomer && !(getAccessType == 'vet' && !getHasAssociatedCalendar) && !getShowingAppointmentData">
          <div class="field is-grouped">
            <p class="control">
              <a @click="endShowData" class="button is-primary">
                {{ $t('General.Back') }}
              </a>
            </p>
          </div>
        </div>
        <div class="navbar-item">
          <div class="field is-grouped">
            <p class="control">
              <a class="button is-danger is-outlined" @click="doLogout">
                <span class="icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="w-6 h-6" width="24" height="24">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M5.636 5.636a9 9 0 1012.728 0M12 3v9" />
                  </svg>
                </span>
                &nbsp;
                <span>  {{ $t('Navbar.Logout') }}
              </span>
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "Navbar",
  data() {
    return {
      componentName: "Navbar",
      iconPath: process.env.VUE_APP_ICON_URL, // Access the Icon URL from environment variables
      isNavbarOpen: true,
      useAppointsAccessCodes: process.env.VUE_APP_USE_APPOINTS_ACCESS_CODES === "true"
    }
  },
  components: {},
  props: [],
  computed: {
    ...mapGetters(['getAccessType', 'getShowingUserData', 'getShowingPetRecord', 'getMngMode', 'getCustDetailing', 'getVetMode',
      'getEditingCustomer', 'getHasAssociatedCalendar', 'getShowingAppointmentData']),
  },
  methods: {
    ...mapActions(['logout', 'toggleShowingUserData', 'iniVetsManagement', 'iniManagersManagement', 'iniCustomersManagement', 'fetchUsers',
      'iniVetAvailabilityConfig', 'iniVetAvailabilitySlots', 'iniVetCustomers', 'toggleShowingPetRecord', 'toggleCustDetailing', 'iniVetAppointments',
      'iniAppointmentsManagement', 'iniProductsManagement', 'toggleShowingAppointmentData', 'toggleShowingHistoricalAppointmentData', 'toggleShowingPetRecord',
      'toggleEditedCustPetEditing', 'toggleAppointmentDetailing', 'toggleShowingProductData', 'toggleSchedulingAppointment', 'iniAppointmentsAccessCodesManagement']),

    resetFlags() {
      this.toggleShowingUserData(true);
      this.toggleShowingPetRecord(true);
      this.toggleCustDetailing(true);
      this.toggleShowingAppointmentData(true);
      this.toggleShowingHistoricalAppointmentData(true);
      this.toggleShowingPetRecord(true);
      this.toggleEditedCustPetEditing(true);
      this.toggleAppointmentDetailing(true);
      this.toggleShowingProductData(true);
      this.toggleSchedulingAppointment(true);
      this.switchManagementMode('vets')
      this.iniVetAvailabilityConfig();
    },

    goToLandingScreen() {
    // Reset all necessary flags
    this.resetFlags();

    // Navigate to the landing screen
    this.$router.push(`/${this.getAccessType}`);
    },
      
    toggleNavbar() {
      this.isNavbarOpen = !this.isNavbarOpen;
    },

    doLogout() {
      this.resetFlags();
      this.logout();
      this.$router.push('/')
    },
    

    showUserdata() {
      const language = this.$i18n.locale;
      const accessType = this.getAccessType;
      this.toggleShowingUserData(false);
      console.log(accessType);
      let route;
      switch (accessType) {
        case "customer":
          route = "/customerdata";
          break;
        case "manager":
          route = "/managerdata";
          break;
        case "vet":
          route = "/vetdata";
          break;
      }
      this.$router.push({ path: route, query: { lang: language, access: accessType } });
    },

    endShowData() {
      // this.$router.go(-1);
      this.toggleShowingUserData(true);
      this.$router.push(`/${this.getAccessType}`)
    },

    populateUsersList(mode: string) {
      var usersType: string;
      switch (mode) {
        case "vets":
          usersType = 'vet'
          break;
        case "managers":
          usersType = 'manager'
          break;
        case "customers":
          usersType = 'customer'
          break;
        case "appointments":
          usersType = 'vet'
          break;
        default:
          usersType = 'customer'
          break;
      }
      this.fetchUsers(usersType);
    },

    switchManagementMode(newMngMode: string) {
      switch (newMngMode) {
        case "vets":
          this.iniVetsManagement();
          break;
        case "managers":
          this.iniManagersManagement();
          break;
        case "customers":
          this.iniCustomersManagement();
          break;
        case "appointments":
          this.iniAppointmentsManagement();
          break;
        case "products":
          this.iniProductsManagement();
          break;
        case "appointments_access_codes":
          this.iniAppointmentsAccessCodesManagement();
          break;
        default:
          this.iniCustomersManagement();
          break;
      }
      this.populateUsersList(this.getMngMode);
    },

    switchVetMode(newVetMode: string) {
      switch (newVetMode) {
        case "availabilityconfig":
          this.iniVetAvailabilityConfig();
          break;
        case "availabilityslots":
          this.iniVetAvailabilitySlots();
          break;
        case "customers":
          this.iniVetCustomers();
          break;
        case "appointments":
          this.iniVetAppointments();
          break;
        default:
          this.iniVetCustomers();
          break;
      }
    }
  },
  watch: {},
  mounted() {
    switch (this.getAccessType)
    {
      case "manager":
        this.populateUsersList(this.getMngMode);
        break;
      case "vet":
        this.populateUsersList('customers');
        break;
    }
    this.resetFlags();
  }
})
</script>

<style scoped>
@media (max-width: 768px) {
  .buttons-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .buttons-container .navbar-item {
    margin-right: 10px; /* Adjust spacing as needed */
  }
}
</style>


