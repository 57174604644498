<template>
  <th>
    {{ appointmentsAccessCodeData.valid_from }}
  </th>
  <td>
    <div>{{ appointmentsAccessCodeData.valid_to }}</div>
  </td>
  <td>
    <div>{{ appointmentsAccessCodeData.code }}</div>
  </td>
</template>

<script lang="ts">
import { defineComponent, defineProps } from 'vue'

export default defineComponent({
  name: "AppointmentsAcessCodesTableRow"
})
</script>
<script lang="ts" setup>

const props = defineProps(['appointmentsAccessCodeData'])

</script>
<style scoped>

</style>
