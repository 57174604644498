<template>
  <div>
    <Navbar></Navbar>
    <section class="hero">
      <div class="hero-body">
        <div class="container" >
          <div class="content">
            <div class="columns is-multiline">
              <!-- Left Column of the Screen-->
              <div :class="{ 'column': true, 'is-6': !getSchedulingAppointment, 'is-4': getSchedulingAppointment, 'is-full-mobile': true }">
                <h4 class="title is-4">
                  {{ $t('Customer.VideoAppointment') }}
                </h4>
                <h6 v-if="!getSchedulingAppointment" class="subtitle is-6 has-text-grey">{{ $t('Customer.TalkFavoriteVet') }}
                </h6>
                <form class="column is-flex is-flex-direction-column is-justify-content-center"
                  @submit.prevent="scheduleAppointment">

                  <!-- Code to Access Appointments Scheduling -->
                  <div class="field" v-if="useAppointsAccessCodes && !getSchedulingAppointment">
                    <div class="columns is-mobile is-align-items-center is-gapless">
                      <div class="column is-narrow">
                        <label class="label mr-2">{{ $t('Customer.EnterAppointmentsAccessCode') }}</label>
                      </div>
                      <div class="column is-flex is-align-items-center">
                        <input type="text" class="input mr-2" v-model.trim="appointmentCode" required />
                      </div>
                      <!-- Submit Code Button -->
                      <div class="column is-narrow">
                        <button type="button" @click="submitCode" class="button is-small is-primary mr-2">{{ $t('Customer.SubmitCode') }}</button>
                      </div>
                      <div class="column is-narrow">
                        <!-- Error message -->
                        <p v-if="codeValidationError" class="help is-danger input-error">{{ $t('Customer.InvalidAccessCode') }}</p>
                        <!-- Success message -->
                        <p v-if="isCodeValid" class="help is-success input-success">{{ $t('Customer.ValidAccessCode') }}</p>
                      </div>
                    </div>
                  </div>


                  <!-- Pet Selection-->
                  <div class="field is-flex is-align-items-center" v-if="!getSchedulingAppointment">
                    <label class="label mt-0 mr-2">{{ $t('Customer.PetName') }}</label>
                    <div class="control is-flex-grow-1" v-if="getCustomerHasPets">
                      <div class="select is-fullwidth">
                        <select v-model="appointment_pet_id" required class="px-6">
                          <option v-for="pet of getEditedCustomerPets" :key="pet.pet_id" :value="pet.pet_id">{{ pet.name
                          }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div v-else>
                      <div class="notification is-danger is-light is-small" v-html="$t('CustomerData.PleaseAddPets')" style="padding: 0.5rem; margin: 0.5rem 0;">
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <label class="label mt-4">{{ $t('Customer.PetName') }}</label>
                    <div class="control">
                      <p>{{ selectedPetName }}</p>
                    </div>
                  </div>

                  <!-- Chief Complaint -->
                  <div class="field" v-if="!getSchedulingAppointment">
                    <label class="label mt-0">{{ $t('Customer.ChiefComplaint') }}</label>
                    <div class="control">
                      <textarea class="textarea" :placeholder="$t('Customer.DescChiefComplaint')" v-model.trim="description"
                        required :disabled="!getCustomerHasPets"></textarea>
                    </div>
                  </div>
                  <div v-else>
                    <label class="label mt-4">{{ $t('Customer.ChiefComplaint') }}</label>
                    <div class="control mb-4">
                      <p>{{ description }}</p>
                    </div>
                  </div>

                  <!-- Attached Files -->
                  <FileInput ref="fileInput" @fileChange="handleFileUpload" :disabled="isLoadingPaymentForm" />

                  <div v-if="!getSchedulingAppointment" class="field">
                    <!-- Scheduling Date -->
                    <div class="field is-flex is-align-items-center">
                      <label class="label mt-3 mr-2">{{ $t('Customer.SelectSchedulingDate') }}</label>
                      <div class="control is-flex-grow-1">
                        <VueDatePicker id="scheduling_date" v-model="schedulingDate" :placeholder="$t('General.PleaseSelectDate')"
                          model-type="yyyy-MM-dd" auto-apply :min-date="new Date()" :max-date="maxDate"
                          :enable-time-picker="false" prevent-min-max-navigation format="yyyy-MM-dd"/> 
                      </div>
                      <!-- Help Icon -->
                      <div class="ml-2">
                        <span class="icon has-tooltip-arrow has-tooltip-right" :data-tooltip="$t('Customer.SchedulingDateHelp')">
                          <i class="fas fa-info-circle has-text-success"></i>
                        </span>
                      </div>
                    </div>
                    <!-- Veterinarian Selection -->
                    <label class="label mt-3">{{ $t('Customer.SelectVetSpecialist') }}</label>
                    <div class="control">
                      <div class="select is-fullwidth" :class="{ 'is-loading': vetsListLoading }">
                        <select v-model="appointment_vet_user_id" required class="px-6" :disabled="!getCustomerHasPets">
                          <option v-for="vet of availableVets" :key="vet.user_id" :value="vet.user_id">{{ vet.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <!-- Veterinarian Mobile -->
                    <div v-if="appointment_vet_user_id > 0" class="is-hidden-desktop my-4 card p-2">
                      <div class="px-3 py-3 is-size-5 has-text-weight-semibold">
                        {{ $t('Customer.ImYourSpecialist') }}
                      </div>
                      <div class="is-flex">
                        <div class="is-flex-grow-1">
                          <figure class="image is-128x128">
                            <img class="is-rounded" :src="specialistProfileImageURL" alt="no_profile_image">
                          </figure>
                        </div>
                        <div class="subtitle is-flex is-flex-direction-column is-justify-content-center">
                          <div class="is-size-6 has-text-weight-semibold has-text-grey-dark">
                            {{ requestedVetData[0].name }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- GDPR Terms -->
                  <div v-if="!getSchedulingAppointment" class="field" :disabled="!getCustomerHasPets">
                    <label class="checkbox mt-0">
                      <input type="checkbox" v-model="gdprChecked" required>
                      {{ $t('Customer.GDPRPrev') }}
                      <a :href="gdprTermsUrl" target="_blank">{{ $t('Customer.GDPRLink') }}</a>
                      {{ $t('Customer.TermsPrev') }}
                      <a :href="termsUrl" target="_blank">{{ $t('Customer.TermsLink') }}</a>
                    </label>
                  </div>

                  <!-- Telemedicine authorization -->
                  <div v-if="!getSchedulingAppointment" class="field" :disabled="!getCustomerHasPets">
                    <label class="checkbox mt-0">
                      <input type="checkbox" v-model="telemedicineAuthChecked" required>
                      {{ $t('Customer.TelemedicineAuthPrev') }}
                      <a :href="authPrevUrl" target="_blank">{{ $t('Customer.TelemedicineAuthLink') }}</a>
                      {{ $t('Customer.TelemedicineAuthNext') }}
                    </label>
                  </div>

                  <!-- Schedule appointment -->
                  <div v-if="!getSchedulingAppointment" class="has-text-centered">
                    <button type="submit" class="button is-primary"
                      :class="{ 'is-loading': isCreatingAppointment }"
                                :disabled="!gdprChecked || !telemedicineAuthChecked || !getCustomerHasPets ||
                                           (useAppointsAccessCodes && !isCodeValid)">
                              {{ $t('Customer.ScheduleAppointment') }}
                    </button>
                  </div>
                </form>

                <!-- Submit payment -->
                <form class="hidden" :action="getPaymentEndpoint()" method="POST" id="paymentForm">
                  <input type="hidden" name="payment_hash" v-model="paymentHash">
                  <input type="hidden" name="payment_email" v-model="paymentEmail">
                  <input type="hidden" name="customer_user_id" v-model="customerUserId">
                  <input type="hidden" name="auth_token" v-model="authToken">
                  <input type="hidden" name="ga_client_id" v-model="gaClientId">
                </form>

                <!-- Veterinarian after Schedule Meeting -->
                <div v-if="getSchedulingAppointment" class="field mt-4">
                  <label class="label">{{ $t('Customer.VetSpecialist') }}</label>
                </div>
                <div v-if="getSchedulingAppointment" class="card">
                  <div class="is-flex is-align-items-center ">
                    <figure class="image is-128x128">
                      <img class="is-rounded" :src="specialistProfileImageURL" alt="no_profile_image">
                    </figure>
                    <div class="is-flex is-flex-direction-column">
                      <div class="is-size-5 has-text-grey-dark">{{ requestedVetData[0].name }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="getSchedulingAppointment" class="has-text-centered mt-4">
                  <button @click="endScheduleAppointment" class="button is-primary" :disabled="isLoadingPaymentForm">{{ $t('General.Back') }}
                  </button>
                </div>
              </div>

              <!--Right Column of the Screen-->
              <div :class="{ 'column': true, 'is-hidden-mobile': !getSchedulingAppointment, 'is-6': !getSchedulingAppointment, 'is-8': getSchedulingAppointment, 'is-flex': true, 'is-flex-direction-column': true, 'is-align-items-center': true }">
                <!--Cat image-->
                <figure v-if="!getSchedulingAppointment" class="image mb-1" style="width: 90%; height: auto;">
                  <img :src="imagePath" alt="cat">
                </figure>
                
                <!-- Veterinarian desktop -->
                <div v-if="appointment_vet_user_id > 0 && !getSchedulingAppointment" class="card is-hidden-mobile" >
                  <div class="card-header mt-0 mb-0 px-2 py-1 is-size-5 has-text-weight-bold">
                    <p class="card-header-title">{{ $t('Customer.ImYourSpecialist') }}</p>
                  </div>
                  <div class=" card-content is-flex is-align-items-center mt-0 mb-0 py-1 px-2">
                    <div class="media">
                      <div class="media-left">
                        <figure class="image is-128x128 mt-0 mb-1 py-1 px-0">
                          <img class="is-rounded" :src="specialistProfileImageURL" alt="no_profile_image">
                        </figure>
                      </div>
                      <div class="media-content mt-0 mb-1">
                        <p class="title is-4 py-1 px-1">{{ requestedVetData[0].name }}</p>
                        <p class="subtitle is-6 py-1 px-1">{{ $t('Customer.LetsSolveTogether') }}</p>
                      </div>
                    </div>
                    </div>
                </div>
                <!--Aurinko Scheduler-->
                <div v-if="getSchedulingAppointment">
                  <div v-if="isLoadingPaymentForm" class="scheduler-container is-flex is-flex-direction-column is-align-items-center">
                    <div class="image-container">
                      <div>
                        <img src="https://i.imgur.com/uA3fRLZ.png" class="image is-fullwidth" alt="logo">
                      </div>
                      <div class="is-flex is-flex-direction-column is-justify-content-center">
                        <div>{{ $t('Customer.WaitForPayment') }}</div>
                        <progress class="progress is-primary"></progress>
                      </div>
                    </div>
                  </div>
                  <iframe v-else id="AppointmentScheduler" :src="iframeSrc" class="scheduler-container"
                    style="padding: 0; overflow-y: hidden; overflow-x: hidden;">
                  </iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>



<script lang="ts">
import { defineComponent, onMounted} from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { mapActions, mapGetters } from "vuex";
import Navbar from "../components/Navbar.vue";
import FileInput from '@/components/FileInput.vue';
import { changeLocale } from "@/main"; // Import the changeLocale function
import { Notyf } from "notyf";

interface FileInputRef {
  clear: () => void; // Define the clear method
}

const notyf = new Notyf()

export default defineComponent({
  name: 'Customer',
  components: {
    Navbar,
    FileInput
  },
  data() {
    return {
      isCreatingAppointment: false,
      appointment_pet_id: 0,
      appointment_vet_user_id: 0,
      description: '',
      paymentHash: '',
      paymentEmail:'',
      customerUserId:'',
      authToken:'',
      gaClientId:'',
      selectedFiles: [] as File[],
      calendarEventId:'',
      gdprChecked: false,
      telemedicineAuthChecked: false,
      route: useRoute(),
      schedulingDate: null,
      imagePath: process.env.VUE_APP_IMAGE_URL, // Access the Image URL from environment variables
      vetsListLoading: false,
      isLoadingPaymentForm: false,
      loadingAurinko: true,
      appointmentCode: '',
      isCodeValid: false,
      codeValidationError: false,
      useAppointsAccessCodes: process.env.VUE_APP_USE_APPOINTS_ACCESS_CODES === "true"
    }; 
  },
  computed: {
    ...mapGetters(['getUsers', 'userData', 'getEditedCustomerData', 'getEditedCustomerPets', 'getAccessType', 'token', 'getCustomerHasPets',
                   'getAvailableVetsUserIDs', 'getSchedulingAppointment']),

    gdprTermsUrl() {
      return `${process.env.FULL_BASE_URL}/terms/DataPrivacyPolicy_${this.$i18n.locale}.pdf`;
    },
    termsUrl() {
      return `${process.env.FULL_BASE_URL}/terms/Customer_TermsAndConditions_${this.$i18n.locale}.pdf`;
    },

    authPrevUrl() {
      return `${process.env.FULL_BASE_URL}/terms/Telemedicine_Authorization_${this.$i18n.locale}.pdf`;
    },

    /* Old version - monthly profiles
    
    iframeSrc()
      {
        // Generate the URL dynamically based on the current mode and other relevant data
        // This third version uses the new URL set dynamically (ex: https://v2-test.wisevetlive.com/wisevet-live/calendar/# )
        // VUE_APP_AURINKO_URL is set in vue.config.js
        const baseUrl = process.env.VUE_APP_AURINKO_URL;
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1; // Months are 0-indexed
        const nextMonth = currentMonth +1 ;
        const profile = `profile=AvailabilityConfig_Vet_${process.env.CLIENT_DESIGNATION}_${this.requestedVetData[0].vet_id}_Month_${currentMonth}`;
        const extensionProfile = `extensionProfile=AvailabilityConfig_Vet_${process.env.CLIENT_DESIGNATION}_${this.requestedVetData[0].vet_id}_Month_${nextMonth}`;
        const suppressInvitation = `suppressInvitation=true`;
        const email = `email=${this.userData.email}`;
        const name = `name=${this.userData.name}`;
        const layout = "layout=month";
        const mode = "";
        const date = `date=${this.schedulingDate}`;

        return `${baseUrl}${profile}&${extensionProfile}&${suppressInvitation}&${email}&${name}&${layout}&${mode}&${date}`;
      },

    */

    /* Newest version - unique profile */
      
    iframeSrc()
      {
        // Generate the URL dynamically based on the current mode and other relevant data
        const baseUrl = process.env.VUE_APP_AURINKO_URL;
        const currentDate = new Date();
        const profile = `profile=AvailabilityConfig_Vet_${process.env.CLIENT_DESIGNATION}_${this.requestedVetData[0].vet_id}`;
        const suppressInvitation = `suppressInvitation=true`;
        const email = `email=${this.userData.email}`;
        const name = `name=${this.userData.name}`;
        const layout = "layout=month";
        const mode = "";
        const date = `date=${this.schedulingDate}`;
        const emptyPrefix = "...";
        const confirmationPrefix = `confirmationPrefix=${emptyPrefix}`;

        return `${baseUrl}${profile}&${suppressInvitation}&${email}&${name}&${layout}&${mode}&${date}&${confirmationPrefix}`;

      },

    requestedVetData(): any {
      if (this.appointment_vet_user_id > 0) {
        return this.getUsers.filter((vet: any) => {
          return vet.user_id === this.appointment_vet_user_id;
        });
      }
      else {
        return null;
      }
    },
    specialistProfileImageURL(): string {
      return process.env.BASE_URL + `api/users/image/${this.appointment_vet_user_id}`;
    },
    selectedPetName(): string {
      const selectedPet = this.getEditedCustomerPets.find((pet: { pet_id: number }) => pet.pet_id === this.appointment_pet_id);
      return selectedPet ? selectedPet.name : '';
    },
    // Compute maxDate as today plus one month
    maxDate(): Date
      {
        const today = new Date();
        const nextMonth = new Date(today.getFullYear(), today.getMonth() + 1, today.getDate());
        return nextMonth;
      },
      
    availableVets()
      {
        if (this.schedulingDate != null)
        {
          return this.getUsers.filter((user: any) =>
          {
            //returns only the vets that are available on the selected date
            return this.getAvailableVetsUserIDs.includes(user.user_id);
          })
        }
        else
        {
          return this.getUsers;
        };
      }
  },
  methods: {
    ...mapActions(['fetchUsers', 'iniEditedCustomer', 'iniEditedCustomerPets', 'IniAccessTypeCustomer', 'toggleShowingUserData', 'iniEditedVet',
                   'requestAppointment', 'fetchAvailableVetsUserIDs', 'toggleSchedulingAppointment', 'validateAccessCode']),

    
  async submitCode()
  {
    try
    {
      // Reset the validation error state
      this.codeValidationError = false;

      // Validate the access code
      const valid = await this.validateAccessCode(this.appointmentCode);

      if (valid)
      {
        // Code is valid, proceed with appointment creation or other logic
        this.isCodeValid = true;
        // Add any other action you want to take here, such as redirecting or showing appointment form
      }
      else
      {
        // Invalid code, show error message
        this.isCodeValid = false;
        this.codeValidationError = true;
      }
    }
    catch (error)
    {
      console.error('Error validating code:', error);
      this.codeValidationError = true;
    }
  },

  clearFormData()
    {
      this.appointment_pet_id = 0;
      this.appointment_vet_user_id = 0;
      this.description = '';
      this.paymentHash = '';
      this.paymentEmail = '';
      this.customerUserId = '';
      this.authToken = '';
      this.gaClientId = '';
      this.selectedFiles = [] as File[];
      this.gdprChecked = false;
      this.telemedicineAuthChecked = false;
      this.schedulingDate = null;
      this.calendarEventId = '';
    },
    
    // Method to construct endpoint URL with language as the first argument
    getPaymentEndpoint()
    {
      // Modify the endpoint URL based on the selected language (i18n.locale)
      return process.env.BASE_URL + `api/payment/${this.$i18n.locale}`;
    },
    
    scheduleAppointment() {

      this.toggleSchedulingAppointment(false);
    },
    endScheduleAppointment() {

      this.toggleSchedulingAppointment(true);
    },

    async requestAppointmentMeth(appointmentTime: string, calendarEventId: string)
    {
      this.isCreatingAppointment = true;
      const appointmentFormData = new FormData();

      try
      {
        // Get the client ID from Google Analytics
        this.gaClientId = await this.getGoogleAnalyticsClientId();

        // Add appointment data to FormData
        appointmentFormData.append
        (
          'appointment_data',
          JSON.stringify
          (
            {
              customer_user_id: this.getEditedCustomerData.user_id,
              pet_id: this.appointment_pet_id,
              vet_user_id: this.appointment_vet_user_id,
              description: this.description,
              schedule: appointmentTime,
              calendar_event_id: calendarEventId,
              timezone_diff: 0,
              vet_observations: ''
            }
          )
        );
      
        // Append selected files
        for (const file of this.selectedFiles)
        {
          appointmentFormData.append('files[]', file);
        }

        // Log the content of appointmentFormData
        appointmentFormData.forEach((value, key) => {
          if (value instanceof File) {
            console.log(`${key}: [File] ${value.name}`);
          } else {
            console.log(`${key}: ${value}`);
          }
        });

        const res = await this.requestAppointment(appointmentFormData);
        this.paymentHash = res.appointment_hash;
        this.paymentEmail = this.userData.email;
        this.customerUserId = this.getEditedCustomerData.user_id;
        this.authToken = this.token;
        this.isCreatingAppointment = false;
        setTimeout(() => { this.submitPaymentForm(); }, 0);
      }
      catch (error)
      {
        // Handle error
        console.error('Failed to request appointment:', error);
      }
    },

    // Helper method to retrieve the clientId from Google Analytics
    getGoogleAnalyticsClientId(): Promise<string>
    {
      return new Promise((resolve, reject) =>
      {
        if (window.gtag)
        {
          window.gtag('get', process.env.VUE_APP_GA_ACCOUNT_ID, 'client_id', (clientId: string) =>
          {
            console.log("clientId: " + clientId);
            if (clientId)
            {
              resolve(clientId);
            }
            else
            {
              reject(new Error('Client ID not found'));
            }
          });
        }
        else
        {
          reject(new Error('Google Analytics is not initialized'));
        }
        
      });
    },

    submitPaymentForm()
    {
      const form = document.getElementById("paymentForm");
      if (form)
      {
        (form as any).submit();
      }
      else
      {
        console.log('Form not found');
      }
    },


    async initializeAppointmentCustomer()
    {
      console.log("initializeAppointmentCustomer")
      this.clearFormData()
      console.log(this.userData.email);
      const resultIniCust = await this.iniEditedCustomer(this.userData.email);
      console.log(resultIniCust);
      console.log(this.getEditedCustomerData);
      console.log(this.getEditedCustomerData.user_id);
      const resultIniPets = await this.iniEditedCustomerPets(this.getEditedCustomerData.user_id);
      console.log(resultIniPets);
      console.log(this.getEditedCustomerPets);
    },
    

    handleFileUpload(files: File[])
    {

      // Maximum file size in bytes (20MB)
      const MAX_TOTAL_SIZE = 20 * 1024 * 1024; // 20MB in bytes

      // Calculate the total size of the selected files
      const totalSize = files.reduce((total, file) => total + file.size, 0);

      // Check if the total size exceeds the maximum allowed size
      if (totalSize > MAX_TOTAL_SIZE) {
        notyf.error(this.$t('FilesData.MaxFilesSizeExceededMessage'));
        // Reset the file input field
        (this.$refs.fileInput as FileInputRef).clear(); // Cast to FileInputRef
        return; // Stop processing if the size exceeds the limit
      }

      // Update selectedFiles with the emitted files array
      this.selectedFiles = files;
    },
  },

  watch: {
    async schedulingDate(newDate, oldDate)
    {
      this.vetsListLoading = true;
      try
      {
        await this.fetchAvailableVetsUserIDs(newDate);
      } 
      catch (error)
      {
        console.error('Failed to fetch specialists:', error);
      }
      finally
      {
        this.vetsListLoading = false;
      }
    }
  },

  beforeMount() {
    const lang = this.route.query.lang as string;

    if (lang)
    {
      try
      {
        changeLocale(lang);
      }
      catch (error)
      {
        console.error('Failed to change language:', error);
      }
    }
    else
    {
      console.log("lang does not exist");
    }
    this.IniAccessTypeCustomer();
  },

  mounted()
  {
    // ensure the translation language is passed correctly through the router and applied in this target component.
    const route = useRoute();
    const { locale } = useI18n();
    onMounted(() => {
      const lang = route.query.lang;
      if (typeof lang === 'string')
      {
        locale.value = lang;
      }
    });


    window.addEventListener
    (
      "message", async (event: MessageEvent) =>
      {
        // Get the iframe element by its id and cast it to HTMLIFrameElement
        const iframe = document.getElementById('AppointmentScheduler') as HTMLIFrameElement;
        // Check if the message is coming from the iframe
        console.log(event)
        console.log(event.source)
        console.log(event.data)
        const eventJsonData = JSON.parse(event.data)
        if (event.source === iframe.contentWindow)
        {
          // Check the message content
          console.log("event.source === iframe.contentWindow")
          if (eventJsonData['status'] === "success")
          {
            // Message received from iframe, do something
            console.log("Received 'success' message from AppointmentScheduler");
            // Avoid an error where there were appointment records inserted with vet_user_id == 0, pet_id == 0, and description == ''
            // The suspicion is that this event is generated more times than expected. With the same google calendar event id.
            if (this.isCreatingAppointment != true &&
                this.isLoadingPaymentForm != true &&
                this.appointment_vet_user_id > 0 &&
                this.appointment_pet_id > 0 &&
                this.description != '' &&
                this.token &&
                this.userData.email &&
                eventJsonData['eventId'] != this.calendarEventId) 
            {
              this.isLoadingPaymentForm = true;
              this.calendarEventId = eventJsonData['eventId'];
              await this.requestAppointmentMeth(eventJsonData['startTime'], this.calendarEventId);

              await new Promise(resolve => setTimeout(resolve, 2000)); // 2 seconds delay
              
              this.isLoadingPaymentForm = false;
            };
          }
        }
      },
      false
    );
  
    this.fetchUsers('vet');
    this.initializeAppointmentCustomer();
    this.toggleShowingUserData(true);
  
  },
});
</script>
<style scoped>
.smaller-font-size
{
  font-size: smaller;
  /* You can adjust this value as needed */
}
.is-flex-grow-1
{
  flex-grow: 1;
}
.scheduler-container {
  width: 900px;
  height: 660px;
}
/* Media Queries */
@media (max-width: 768px) {
  .scheduler-container {
    width: 376px;
    height: 1000px; /* Adjust this value as needed */
  }
  .image-container {
    width: 100%;
    max-width: 365px; /* Adjust as needed */
  }
  .hero-body {
    padding: 0.5rem; /* Reduced padding */
  }
  .container,
  .content {
    max-width: 100%; /* Full width for narrow screens */
    padding: 0.5rem; /* Reduced padding */
  }
  .columns.is-multiline {
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* Less gap between columns */
  }
  .field {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
  }
  .label {
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 1025px) and (min-width: 769px) {
  .scheduler-container {
    width: 376px;
    height: 1000px; /* Adjust this value as needed */
  }
  .image-container {
    width: 100%;
    max-width: 365px; /* Adjust as needed */
  }
  .hero-body {
    padding: 0.5rem; /* Reduced padding */
  }
  .container,
  .content {
    max-width: 100%; /* Full width for narrow screens */
    padding: 0.5rem; /* Reduced padding */
  }
  .field {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
  }
  .label {
    margin-bottom: 0.5rem;
  }
}
.icon[data-tooltip] {
  position: relative;
  cursor: pointer;
}

.icon[data-tooltip]::after {
  content: attr(data-tooltip);
  position: absolute;
  bottom: 125%; /* Adjusted position */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 0.5rem; /* Reduced padding */
  border-radius: 4px; /* Slightly rounded corners */
  white-space: normal;
  max-width: 250px; /* Limited width for smaller tooltips */
  text-align: center;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
  z-index: 1000;
}

.icon[data-tooltip]:hover::after,
.icon[data-tooltip]:focus::after,
.icon[data-tooltip]:active::after {
  opacity: 1;
  pointer-events: auto;
}
.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #23d160; /* Bulma success button color */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>
