import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-49d65fc8"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "title is-4"
}
const _hoisted_2 = {
  key: 0,
  class: "block"
}
const _hoisted_3 = { class: "columns" }
const _hoisted_4 = { class: "column is-2" }
const _hoisted_5 = { class: "label" }
const _hoisted_6 = { class: "control" }
const _hoisted_7 = { class: "column is-3" }
const _hoisted_8 = { class: "label" }
const _hoisted_9 = { class: "control" }
const _hoisted_10 = { class: "column is-2" }
const _hoisted_11 = { class: "label" }
const _hoisted_12 = { class: "control is-flex is-align-items-center" }
const _hoisted_13 = { class: "mr-5 mb-0" }
const _hoisted_14 = {
  key: 0,
  class: "column is-2"
}
const _hoisted_15 = { class: "label" }
const _hoisted_16 = { class: "control" }
const _hoisted_17 = { class: "column is-3" }
const _hoisted_18 = { class: "label" }
const _hoisted_19 = { class: "control" }
const _hoisted_20 = {
  key: 0,
  class: "field"
}
const _hoisted_21 = { class: "label mt-0" }
const _hoisted_22 = { class: "control" }
const _hoisted_23 = ["placeholder"]
const _hoisted_24 = { key: 1 }
const _hoisted_25 = { class: "label" }
const _hoisted_26 = { class: "control" }
const _hoisted_27 = {
  key: 1,
  class: "block"
}
const _hoisted_28 = {
  key: 0,
  class: "field"
}
const _hoisted_29 = { class: "label" }
const _hoisted_30 = { class: "control" }
const _hoisted_31 = ["placeholder"]
const _hoisted_32 = { key: 1 }
const _hoisted_33 = { class: "label" }
const _hoisted_34 = { class: "control" }
const _hoisted_35 = {
  key: 2,
  class: "content-body"
}
const _hoisted_36 = { class: "label mt-4" }
const _hoisted_37 = { class: "table-container mb-2" }
const _hoisted_38 = { class: "table" }
const _hoisted_39 = { class: "sticky-column" }
const _hoisted_40 = { class: "" }
const _hoisted_41 = { class: "" }
const _hoisted_42 = { class: "" }
const _hoisted_43 = { class: "" }
const _hoisted_44 = { key: 0 }
const _hoisted_45 = { class: "content-body" }
const _hoisted_46 = {
  key: 0,
  class: "header is-flex is-justify-content-space-between"
}
const _hoisted_47 = { class: "content-body" }
const _hoisted_48 = { class: "field is-grouped is-grouped-right" }
const _hoisted_49 = {
  key: 0,
  class: "control"
}
const _hoisted_50 = {
  type: "submit",
  class: "button is-primary"
}
const _hoisted_51 = {
  key: 1,
  class: "control"
}
const _hoisted_52 = {
  key: 2,
  class: "control"
}
const _hoisted_53 = {
  key: 3,
  class: "control"
}
const _hoisted_54 = { class: "content-body" }
const _hoisted_55 = { class: "header" }
const _hoisted_56 = { class: "title is-5 mt-4" }
const _hoisted_57 = { class: "field is-grouped is-grouped-right" }
const _hoisted_58 = { class: "control" }
const _hoisted_59 = {
  type: "submit",
  class: "button is-primary"
}
const _hoisted_60 = { class: "control" }
const _hoisted_61 = { class: "content-body" }
const _hoisted_62 = { class: "header" }
const _hoisted_63 = { class: "title is-5" }
const _hoisted_64 = { class: "field is-grouped is-grouped-right" }
const _hoisted_65 = { class: "control" }
const _hoisted_66 = {
  type: "submit",
  class: "button is-primary"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppointmentDataFileTableRow = _resolveComponent("AppointmentDataFileTableRow")!
  const _component_AppointmentDataFileDataForm = _resolveComponent("AppointmentDataFileDataForm")!
  const _component_AppointmentDataPetDataForm = _resolveComponent("AppointmentDataPetDataForm")!
  const _component_CustDataPetDataForm = _resolveComponent("CustDataPetDataForm")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.getShowingPetRecord)
      ? (_openBlock(), _createElementBlock("h4", _hoisted_1, _toDisplayString(_ctx.$t('AppointmentData.VideoAppointment')), 1))
      : _createCommentVNode("", true),
    _createElementVNode("form", {
      class: "column is-flex is-flex-direction-column is-justify-content-center",
      onSubmit: _cache[10] || (_cache[10] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.editAppointmentFilesMeth && _ctx.editAppointmentFilesMeth(...args)), ["prevent"]))
    }, [
      (!_ctx.getShowingPetRecord)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.$t('AppointmentData.Schedule')), 1),
                _createElementVNode("div", _hoisted_6, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.getEditedAppointmentData.schedule), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_7, [
                _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.$t('AppointmentData.Customer')), 1),
                _createElementVNode("div", _hoisted_9, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.getEditedAppointmentData.customer_name), 1)
                ])
              ]),
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t('AppointmentData.Pet')), 1),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.getEditedAppointmentData.pet_name), 1),
                  (!_ctx.getShowingPetRecord)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        type: "button",
                        class: "button is-warning is-small",
                        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.iniAppointmentPetRecord && _ctx.iniAppointmentPetRecord(...args)))
                      }, _toDisplayString(_ctx.$t('General.Record')), 1))
                    : _createCommentVNode("", true)
                ])
              ]),
              (_ctx.considerInsurance)
                ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                    _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t('AppointmentData.Policy')), 1),
                    _createElementVNode("div", _hoisted_16, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.getEditedAppointmentData.pet_policy), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_17, [
                _createElementVNode("label", _hoisted_18, _toDisplayString(_ctx.$t('AppointmentData.Vet')), 1),
                _createElementVNode("div", _hoisted_19, [
                  _createElementVNode("p", null, _toDisplayString(_ctx.getEditedAppointmentData.vet_name), 1)
                ])
              ])
            ]),
            (!_ctx.addingFile && _ctx.getEditingAppointment && _ctx.getAccessType == 'customer')
              ? (_openBlock(), _createElementBlock("div", _hoisted_20, [
                  _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t('Customer.ChiefComplaint')), 1),
                  _createElementVNode("div", _hoisted_22, [
                    _withDirectives(_createElementVNode("textarea", {
                      class: "textarea",
                      placeholder: _ctx.$t('Customer.DescChiefComplaint'),
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.getEditedAppointmentData.description) = $event)),
                      required: ""
                    }, null, 8, _hoisted_23), [
                      [
                        _vModelText,
                        _ctx.getEditedAppointmentData.description,
                        void 0,
                        { trim: true }
                      ]
                    ])
                  ])
                ]))
              : (_openBlock(), _createElementBlock("div", _hoisted_24, [
                  _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t('Customer.ChiefComplaint')), 1),
                  _createElementVNode("div", _hoisted_26, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.getEditedAppointmentData.description), 1)
                  ])
                ]))
          ]))
        : _createCommentVNode("", true),
      (!_ctx.getShowingPetRecord)
        ? (_openBlock(), _createElementBlock("div", _hoisted_27, [
            (!_ctx.addingFile && _ctx.getEditingAppointment && _ctx.getAccessType == 'vet')
              ? (_openBlock(), _createElementBlock("div", _hoisted_28, [
                  _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t('AppointmentData.VetObservations')), 1),
                  _createElementVNode("div", _hoisted_30, [
                    _withDirectives(_createElementVNode("textarea", {
                      class: "textarea",
                      placeholder: _ctx.$t('AppointmentData.VetObservationsPlaceHolder'),
                      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.getEditedAppointmentData.vet_observations) = $event)),
                      required: ""
                    }, null, 8, _hoisted_31), [
                      [
                        _vModelText,
                        _ctx.getEditedAppointmentData.vet_observations,
                        void 0,
                        { trim: true }
                      ]
                    ])
                  ])
                ]))
              : (_ctx.getAccessType == 'vet' || _ctx.getAccessType == 'manager')
                ? (_openBlock(), _createElementBlock("div", _hoisted_32, [
                    _createElementVNode("label", _hoisted_33, _toDisplayString(_ctx.$t('AppointmentData.VetObservations')), 1),
                    _createElementVNode("div", _hoisted_34, [
                      _createElementVNode("p", null, _toDisplayString(_ctx.getEditedAppointmentData.vet_observations), 1)
                    ])
                  ]))
                : _createCommentVNode("", true),
            (!_ctx.addingFile)
              ? (_openBlock(), _createElementBlock("div", _hoisted_35, [
                  _createElementVNode("label", _hoisted_36, _toDisplayString(_ctx.$t('FilesData.Files')), 1),
                  _createElementVNode("div", _hoisted_37, [
                    _createElementVNode("table", _hoisted_38, [
                      _createElementVNode("thead", null, [
                        _createElementVNode("tr", null, [
                          _createElementVNode("th", _hoisted_39, _toDisplayString(_ctx.$t('FilesData.FileName')), 1),
                          _createElementVNode("th", _hoisted_40, _toDisplayString(_ctx.$t('FilesData.FileContent')), 1),
                          _createElementVNode("th", _hoisted_41, _toDisplayString(_ctx.$t('FilesData.FileType')), 1),
                          _createElementVNode("th", _hoisted_42, _toDisplayString(_ctx.$t('FilesData.FileSize')), 1),
                          _createElementVNode("th", _hoisted_43, _toDisplayString(_ctx.$t('FilesData.FileUploadTime')), 1),
                          (_ctx.getEditingAppointment)
                            ? (_openBlock(), _createElementBlock("th", _hoisted_44, _toDisplayString(_ctx.$t('General.Operations')), 1))
                            : _createCommentVNode("", true)
                        ])
                      ]),
                      _createElementVNode("tbody", null, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getEditedAppointmentFiles, (file) => {
                          return (_openBlock(), _createElementBlock("tr", {
                            key: file.file_id
                          }, [
                            _createVNode(_component_AppointmentDataFileTableRow, { "file-data": file }, null, 8, ["file-data"])
                          ]))
                        }), 128))
                      ])
                    ])
                  ])
                ]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_45, [
              (!_ctx.addingFile && _ctx.getEditingAppointment)
                ? (_openBlock(), _createElementBlock("div", _hoisted_46, [
                    _createElementVNode("div", null, [
                      _createElementVNode("button", {
                        class: "button is-primary",
                        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.toggleAddingFile && _ctx.toggleAddingFile(...args)))
                      }, [
                        _createElementVNode("strong", null, _toDisplayString(_ctx.$t('FilesData.NewFile')), 1)
                      ])
                    ])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_47, [
              _createElementVNode("div", _hoisted_48, [
                (!_ctx.addingFile && _ctx.getEditingAppointment)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_49, [
                      _createElementVNode("button", _hoisted_50, _toDisplayString(_ctx.$t('General.Save')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.addingFile && _ctx.getEditingAppointment)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_51, [
                      _createElementVNode("a", {
                        onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.cancelEditing && _ctx.cancelEditing(...args))),
                        class: "button is-light"
                      }, _toDisplayString(_ctx.$t('General.Cancel')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (!_ctx.addingFile && !_ctx.getEditingAppointment && (_ctx.getAccessType == 'customer' || _ctx.getAccessType == 'vet'))
                  ? (_openBlock(), _createElementBlock("p", _hoisted_52, [
                      _createElementVNode("a", {
                        onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.iniAppointmentEditing && _ctx.iniAppointmentEditing(...args))),
                        class: "button is-primary"
                      }, _toDisplayString(_ctx.$t('General.ModifyData')), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.getShowingAppointmentData && !_ctx.getEditingAppointment)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_53, [
                      _createElementVNode("a", {
                        onClick: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.endAppointmentDetailing && _ctx.endAppointmentDetailing(...args))),
                        class: "button is-primary"
                      }, _toDisplayString(_ctx.$t('General.Back')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_54, [
              (_ctx.addingFile)
                ? (_openBlock(), _createElementBlock("form", {
                    key: 0,
                    onSubmit: _cache[8] || (_cache[8] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.addFileMeth && _ctx.addFileMeth(...args)), ["prevent"]))
                  }, [
                    _createElementVNode("div", _hoisted_55, [
                      _createElementVNode("h5", _hoisted_56, _toDisplayString(_ctx.$t('FilesData.AddNewFile')), 1)
                    ]),
                    _createVNode(_component_AppointmentDataFileDataForm, { ref: "refNewFileDataForm" }, null, 512),
                    _createElementVNode("div", _hoisted_57, [
                      _createElementVNode("p", _hoisted_58, [
                        _createElementVNode("button", _hoisted_59, _toDisplayString(_ctx.$t('General.Add')), 1)
                      ]),
                      _createElementVNode("p", _hoisted_60, [
                        _createElementVNode("a", {
                          onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.toggleAddingFile && _ctx.toggleAddingFile(...args))),
                          class: "button is-light"
                        }, _toDisplayString(_ctx.$t('General.Cancel')), 1)
                      ])
                    ])
                  ], 32))
                : _createCommentVNode("", true)
            ])
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_61, [
        (_ctx.getShowingPetRecord)
          ? (_openBlock(), _createElementBlock("form", {
              key: 0,
              onSubmit: _cache[9] || (_cache[9] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.endShowingPetRecordMeth && _ctx.endShowingPetRecordMeth(...args)), ["prevent"]))
            }, [
              _createElementVNode("div", _hoisted_62, [
                _createElementVNode("h5", _hoisted_63, _toDisplayString(_ctx.$t('PetData.PetHistoricalRecord')), 1)
              ]),
              (_ctx.getAccessType != 'customer')
                ? (_openBlock(), _createBlock(_component_AppointmentDataPetDataForm, {
                    key: 0,
                    ref: "refPetRecordDataForm"
                  }, null, 512))
                : _createCommentVNode("", true),
              (_ctx.getAccessType == 'customer')
                ? (_openBlock(), _createBlock(_component_CustDataPetDataForm, {
                    key: 1,
                    ref: "refPetRecordDataForm"
                  }, null, 512))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_64, [
                _createElementVNode("p", _hoisted_65, [
                  _createElementVNode("button", _hoisted_66, _toDisplayString(_ctx.$t('General.Back')), 1)
                ])
              ])
            ], 32))
          : _createCommentVNode("", true)
      ])
    ], 32)
  ], 64))
}